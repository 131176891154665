import { useEffect, useState } from "preact/hooks";

interface CardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (fileType: string, cardData: string) => void;
  fileType?: string;
  initialCard?: string;
}

export default function CardModal({ isOpen, onClose, onSave, fileType, initialCard }: CardModalProps) {
  const [cardData, setCardData] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (initialCard) {
      setCardData(initialCard);
      setError("");
    }
  }, [initialCard]);

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    try {
      const parsed = JSON.parse(cardData);
      if (!parsed.Name) {
        throw new Error("Card must have a name");
      }
      onSave(fileType || "", cardData);
      setError("");
    } catch (err) {
      setError(err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg max-w-2xl w-full">
        <h2 class="text-2xl font-bold mb-4">
          {initialCard ? (JSON.parse(initialCard).Name ? 'Edit' : 'Create New') : ''} 
          {fileType === 'units' ? ' Unit' : ' Spell'}
        </h2>
        
        {error && (
          <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <textarea
            class="w-full h-96 font-mono p-4 border rounded"
            value={cardData}
            onInput={(e) => {
              const target = e.target as HTMLTextAreaElement;
              setCardData(target.value);
            }}
          />
          <div class="flex justify-end gap-4 mt-4">
            <button
              type="button"
              class="px-4 py-2 border rounded"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
